const criteria = [
  {
    icon: "DeviceMobile",
    title: "Highly Responsive",
    text: "id ius mutat gubergen, eros harum hendrerit ex eos, in quo vocibus inimigus gubergren. Experience, then believe.",
  },
  {
    icon: "LockKey",
    title: "Built-in Security",
    text: "id ius mutat gubergen, eros harum hendrerit ex eos, in quo vocibus inimigus gubergren. Experience, then believe.",
  },
  {
    icon: "ShieldCheck",
    title: "Safety Locked",
    text: "id ius mutat gubergen, eros harum hendrerit ex eos, in quo vocibus inimigus gubergren. Experience, then believe.",
  },
];

export default criteria;
